import mapboxgl from 'mapbox-gl';

let map = null;
const mapContainer = document.getElementById('subsidiariesMapContainer');

if (mapContainer) {
// check if map is already in viewport
    initMap();

    // add event listener to check if map is in viewport on scroll
    window.addEventListener('scroll', initMap);

    /** init the project map if its container is inside the viewport */
    function initMap () {
        const mapContainerTopPosition = mapContainer.getBoundingClientRect().top - window.innerHeight;

        // Set Mapbox Access Token
        mapboxgl.accessToken = 'pk.eyJ1IjoiYWFlcHBsaSIsImEiOiJjbDc0dTdyaHgwM2VvM290OHRuNGdzbnhvIn0.cqnFpV1bi17pry0GvEf5SQ';

        if (mapContainerTopPosition <= 200 && map === null) {
            window.removeEventListener('scroll', initMap);

            map = new mapboxgl.Map({
                    container: 'subsidiariesMapContainer', // container id
                    style: 'mapbox://styles/aaeppli/cl346ajsk000515ruo41wuuqj', // style URL
                    center: [8.341, 46.753], // starting position [lng, lat]
                    zoom: 7, // starting zoom
                    cooperativeGestures: true
                });

            // Markers
            const geojson = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [7.589, 47.555]
                        },
                        properties: {
                            title: 'dasteam ag',
                            streetAddress: 'Jacob Burckhardt-Strasse 88',
                            zip: '4002',
                            city: 'Basel',
                            tel: '+41 61 278 99 99',
                            fax: '+41 61 278 99 80',
                            mail: 'basel@team.jobs',
                            subsidiaryLink: '#',
                            routeLink: '#',
                            trainLink: '#'
                        }
                    },
                ]
            };

            // Create markers
            for (const feature of geojson.features) {
                /** Create location markers */
                const el = document.createElement('i');
                el.className = 'subsidiariesMap__pin bi bi-geo-alt-fill';

                new mapboxgl.Marker(el, { anchor: 'bottom' })
                    .setLngLat(feature.geometry.coordinates)
                    .setPopup(
                        new mapboxgl.Popup({
                            offset: 15,
                            className: 'card',
                            closeButton: false,
                            anchor: 'top'
                        }) // add popups
                            .setHTML(
                                `<ul class="no-list">
                                <li><strong>${feature.properties.title}</strong><li>
                                <li>${feature.properties.streetAddress}<li>
                                <li>${feature.properties.zip} ${feature.properties.city}<li>
                                <li>Tel: ${feature.properties.tel}<li>                                
                                <li>Fax: ${feature.properties.fax}<li>                                 
                                <li>Mail: <a href="mailto:${feature.properties.mail}">${feature.properties.mail}</a><li>                                  
                                </p>
                                <ul class="no-list">
                                <li><a href="${feature.properties.mail}" target="_blank"><i class="bi bi-house"> </i>Filiale</a></li>
                                <li><a href="${feature.properties.mail}" target="_blank"><i class="bi bi-car-front"> </i>Routenplaner</a></li>
                                <li><a href="${feature.properties.mail}" target="_blank"><i class="bi bi-train-front"> </i>Fahrplan SBB</a></li>
                                </ul>`
                            )
                    )
                    .addTo(map);
            }
        }
    }
}
