const mobileToggle = document.querySelector('.do-toggle-mobileMenu');
const subOpenToggles = document.querySelectorAll('.do-open-subMenu');
const subCloseToggles = document.querySelectorAll('.do-close-subMenu');

/**
 * toggle megaMenu open class
 * @param e {Event}
 */
function toggleMobileMenu (e) {
    const toggle = document.querySelector('.do-toggle-mobileMenu');
    const mobileMenu = document.querySelector('.mobileMenu');

    e.preventDefault();
    mobileMenu.classList.toggle('-open');
    toggle.classList.toggle('-active');
}

/**
 * toggle submenu with the id set in data-menu attribute
 * @param e {Event}
 */
function openSubMenu (e) {
    const subMenu = document.getElementById(e.currentTarget.dataset.menu);

    e.preventDefault();
    // toggle -open class of current subMenu
    subMenu.classList.add('-open');
}

/**
 * toggle submenu with the id set in data-menu attribute
 * @param e {Event}
 */
function closeSubMenu (e) {
    const subMenu = e.currentTarget.closest('.mobileMenu__menu');

    e.preventDefault();
    // toggle -open class of current subMenu
    subMenu.classList.remove('-open');
}

mobileToggle.addEventListener('click', toggleMobileMenu);
subOpenToggles.forEach(subToggle => subToggle.addEventListener('click', openSubMenu));
subCloseToggles.forEach(subToggle => subToggle.addEventListener('click', closeSubMenu));


