/**
 * Scroll event handler: toggle menu active class when scrolled down from top of page
 * @param e {Event}
 */
function stickyMainMenu (e) {
    const stickyBar = document.querySelector('.dcwiNavbar');
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 0) {
        stickyBar.classList.add('-active');
    } else {
        stickyBar.classList.remove('-active');
    }
}

document.addEventListener('scroll', stickyMainMenu);
