const megaToggle = document.querySelectorAll('.do-toggle-megaMenu');
const megaMenus = document.querySelectorAll('.megaMenu');
const subToggles = document.querySelectorAll('.do-toggle-subMenu');

//window.addEventListener('scroll', closeMegaMenu);
megaToggle.forEach(toggle => toggle.addEventListener('click', toggleMegaMenu));
megaMenus.forEach(megaMenu => megaMenu.addEventListener('click', closeMegaMenu));
['click', 'mouseenter'].forEach(evtStr => {
    subToggles.forEach(subToggle => subToggle.addEventListener(evtStr, toggleSubMenu));
});
window.addEventListener('scroll', closeMegaMenuScroll);

/**
 * toggle megaMenu open class
 * @param e {Event}
 */
function toggleMegaMenu (e) {
    const toggle = e.currentTarget;
    const toggles = document.querySelectorAll('.do-toggle-megaMenu.active');
    // if data-menu value exists get menu by id, else get current target
    const megaMenu = document.getElementById(e.currentTarget.dataset.menu) || e.currentTarget;
    const megaMenus = document.querySelectorAll('.megaMenu');

    if (toggle.classList.contains('active')) {
        return true;
    } else {
        e.preventDefault();

        megaMenu.classList.toggle('-open');
        toggles.forEach(toggle => {
            if (toggle !== e.currentTarget) {
                toggle.classList.remove('active');
            }
        });
        megaMenus.forEach(menu => {
            if (menu !== megaMenu) {
                menu.classList.remove('-open');
            }
        });
        if (!toggle.classList.contains('megaMenu')) {
            toggle.classList.toggle('active');
        }
    }
}

/**
 * close megaMenu by clicking outside
 * @description close menu if the clicked element is the megaMenu parent element and not its child
 * @param e {Event}
 */
function closeMegaMenu (e) {
    if (e.target === e.currentTarget) {
        toggleMegaMenu(e);
    }
}

/**
 * close megaMenu by on scroll outside
 * @description close menu if the clicked element is the megaMenu parent element and not its child
 * @param e {Event}
 */
function closeMegaMenuScroll (e) {
    const megaMenus = document.querySelectorAll('.megaMenu.-open');
    const toggles = document.querySelectorAll('.do-toggle-megaMenu.active');

    megaMenus.forEach(menu => menu.classList.remove('-open'));
    toggles.forEach(toggle => toggle.classList.remove('active'));
}

/**
 * toggle submenu with the id set in data-sub-menu attribute
 * @param e {Event}
 */
function toggleSubMenu (e) {
    const toggle = e.currentTarget;
    const otherToggles = document.querySelectorAll('.do-toggle-subMenu');
    const subMenu = document.getElementById(e.currentTarget.dataset.menu);
    const otherSubMenus = subMenu.closest('.row').querySelectorAll('.megaMenu__subMenu:not(#' + e.currentTarget.dataset.menu + ')');
    const wrappingMenu = e.target.closest('.megaMenu__subMenu');

    // prevent default and open submenu if toggle does not have active class
    if (!toggle.classList.contains('active')) {
        e.preventDefault();
        // toggle active class of current toggle
        toggle.classList.add('active');
        // remove active class form other toggles
        otherToggles.forEach(otherToggle => {
            if (otherToggle !== toggle) {
                otherToggle.classList.remove('active');
            }
        });
        // toggle -open class of current subMenu
        subMenu.classList.add('-open');
        // remove -open class of all other subMenus except the menu containing the toggle
        otherSubMenus.forEach(otherSubMenu => {
            if (otherSubMenu !== wrappingMenu) {
                otherSubMenu.classList.remove('-open');
            }
        });
    }
}
